import React from "react"
import FotoEnseña from "@components/pageCasosdeExito/image/imgPhotoEmpresa/enseña/FotoEnseña.png"
import FotoEnseña2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/enseña/FotoEnseña2.png"

//logo de empresas clientes
import Enseña from "@components/pageCasosdeExito/image/logoscustomer/Enseña.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import livechat from "@components/pageCasosdeExito/image/iconcards/livechat.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/enseña/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/enseña/img2.svg"

const text1 = (
  <span className="">
    Enseña Perú, es una asociación sin fines de lucro que contribuye a la
    construcción de un movimiento social para la transformación educativa. Para
    ello, recluta y capacita a profesionales talentosos, quiénes se comprometen
    como profesores de escuelas en todo el país, construyendo comunidades de
    aprendizaje a nivel nacional.
    <br /> <br />
    Beex, a través de sus soluciones omnicanales, ha ayudado a Enseña Perú
    en poder contactar con mayor cantidad de profesionales de manera
    inteligente, por canal de voz y por WhatsApp, logrando un 40% de aumento en
    sus donantes (suscriptores) mensuales.
  </span>
)

const text2 = (
  <span>
    Enseña Perú, cree en el poder de la educación y confía en el potencial de
    todos los peruanos. Viene trabajando desde hace 13 años para lograr una
    educación de calidad. Y su objetivo es realmente poderoso: Quieren un país
    donde cada estudiante tenga la oportunidad de realizar su propósito y
    alcanzar sus sueños, gracias a una educación de primera.
    <br /> <br />
    Su movimiento es liderado por agentes de cambio de diversos sectores, y
    actualmente reclutan profesionales de muchas industrias que se comprometa a,
    por lo menos, 2 años como profesores en escuelas de todo el país.
    <br /> <br />
    Además, atraen directores y docentes para formarlos en técnicas pedagógicas
    y liderazgo, construyendo con ellos comunidades de aprendizaje que operen
    bajo principios de confianza y colaboración.
    <br /> <br />
    Omar García, Coordinador del Programa de Suscriptores de Enseña Perú, ha
    sido el responsable principal de la puesta en producción de las soluciones
    omnicanales de Beex en su operación.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Beex nos brinda un mejor orden en la gestión de nuestras bases y nos da un
    seguimiento mucho más efectivo y podemos auditar el proceso en tiempo real.
    Esto, ha logrado que aumentemos en un 50% la contactabilidad con nuestros
    potenciales donantes.”
  </p>
)

const text4 = (
  <span>
    Como objetivo principal, se buscaba mejorar el contacto con los potenciales
    donantes de la organización, por lo que los marcadores telefónicos de Beex
    han sido fundamentales para este fin. Ahora, las bases en frío y leads
    registrados cuentan con tiempos de contacto mejor del esperado.
    <br /> <br />
    También, el canal de WhatsApp, a través del API oficial, ha ayudado en gran
    medida al recontacto de los leads. Además, de ser un canal ideal para
    manejar campañas de fidelización con sus donantes.
  </span>
)

const text5 = (
  <span>
    Como mencionamos, uno de los principales objetivos de Enseña Perú, era
    aumentar el contacto con sus potenciales donantes (suscriptores), tanto para
    sus bases en frío, como para sus registros de leads por web.
    <br /> <br />
    Para esto, se administraron sus bases telefónicas con las marcaciones
    inteligentes de Beex, como progresivos y predictivos.
    <br /> <br />
    Anteriormente, el proceso de armar, importar y ejecutar campañas era una
    tarea que demandaba mucho tiempo y esfuerzo. Tiempo que sin duda siempre se
    debió invertir en solo contactar inteligentemente a los usuarios.
    <br /> <br />
    Ahora, en solo unos clicks, la operación de Enseña Perú es capaz de diseñar
    y lanzar campañas telefónicas de diferentes marcaciones en cuestión de
    minutos. Y como nos cuenta Omar, el resultado ha sido importante:
  </span>
)
const text6 = ""

const text7 = (
  <span>
    En general, la administración y gestión de la operación de Contact Center de
    Enseña Perú, se ha vuelto más óptima con las soluciones de Beex.
    <br /> <br />
    El tiempo de implementación, como de costumbre, no tomó más de 20 días.
    Luego de este tiempo, Enseña Perú fue autónomo en el uso de las soluciones,
    logrando segmentar sus campañas a su preferencia y tomando acciones de
    contacto por los canales principales.
    <br /> <br />
    Como menciona Omar, los procesos de seguimiento y calidad es algo que han
    encontrado administrar de forma rápida en Beex. Además, contar con una
    plataforma en la nube, ha ayudado a que puedan gestionar su operación desde
    cualquier lugar.
  </span>
)
const text8 = (
  <span>
    “La administración de nuestras campañas en Beex es amigable. La calidad de
    audio que nos brinda es ideal; además que es muy práctico, ya que al estar
    implementado en la nube no necesitamos de instalaciones extras y complejas
    para nuestras conexiones.”
  </span>
)

const text9 = (
  <span>
    Algo que siempre destacamos en Beex, es el acompañamiento que brindamos
    a nuestros clientes. Aparte del soporte que podemos brindar, buscamos que
    cuenten con una experiencia total.
    <br /> <br />
    Esto, ha sido una característica también con Enseña Perú, que cuenta con
    canales de voz, WhatsApp y correo para el reporte de cualquier tipo de
    consulta.
    <br /> <br /> Como menciona Omar, el tipo de atención de Beex Service
    Desk es muy bueno, siempre acompañado de un alto índice de efectividad en
    todos los casos que han podido reportar.
  </span>
)
const text10 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “La calidad de atención por parte de Service Desk es muy buena. No nos
    podemos quejar, siempre atentos y dispuestos a ayudar. Además, siempre hay
    apertura de su lado para integrar o adicionar nuevas características acorde
    a nuestras necesidades.”
  </p>
)
const text11 = (
  <span>
    Como conclusión, Enseña Perú ha logrado llegar a objetivos importantes para
    una de las tareas core de su operación, como lo es el contacto y
    comunicación con sus usuarios. El gestionar de manera rápida e inteligente
    los procesos de contacto de sus donantes (suscriptores), es algo que han
    logrado gracias a los procesos de contactabilidad de Beex.
    <br /> <br />
    Por nuestro lado, estamos felices y orgullosos de ayudar a
    Enseña Perú en esta bonita tarea. El objetivo que tienen trazado es un gran
    beneficio para más peruanos, y es algo que estamos seguros seguirán
    logrando, gracias a la genial operación de humanos con la que cuentan.
  </span>
)

const data = {
  intro: {
    image: Enseña,
    link: "https://www.youtube.com/watch?v=AoImx4dU0lE&ab_channel=Securitec",
    classlogo: "logo__clientes--casa",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoEnseña,
    text4: text4,
    title5: "Marcadores telefónicos de alto contacto",
    text5: text5,
    text6: text6,
    title7: "Contact Center en la nube",
    text7: text7,
    text8: text8,
    title9: "Acompañamiento total ",
    fototwo: FotoEnseña2,
    text9: text9,
    text10: text10,
    text11,
    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para sus operaciones de atención al cliente, marketing y ventas. Beex logra que sus clientes conversen con CAFAE-SE mediante la App más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más. ",
    },
    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots por los canales de más interacción con sus clientes, como WhatsApp y Facebook Messenger. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año. ",
    },
    {
      title: "Omnicanalidad",
      img: livechat,
      info:
        "Sumado a WhatsApp, CAFAE-SE ha integrado Facebook Messenger a Beex, logrando tener conversaciones por estos canales digitales a través de un solo hilo de conversación omnicanal en una sola ventana de conversación. ",
    },
  ],
}

export default data
